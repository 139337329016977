.l-container-JZnr {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
}

.l-BZhydn {
    width: 100%;
    position: absolute;
    white-space: nowrap;
    overflow-x: hidden;
}