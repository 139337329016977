.information-NAhsys {
  background-color: white;
  width: calc(100% - 40px);
  padding: 20px;
}

.title-23sk {
  color: var(--color-accent-red);
  font-size: 22px;
  font-weight: 600;
}

.title-Jsud {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.title-chevron-sjdhSu {
  color: var(--color-accent-red);
}