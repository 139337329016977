.error-header {
  font-size: 32px;
  text-align: center;
}

.error-block-Osnhf {
  padding: 20px;
  width: calc(100% - 40px);
}

.error-code-Jsy {
  text-align: center;
  font-size: 72px;
  line-height: 72px;
  font-weight: bold;
  color: var(--color-accent-red);
}

.error-description-Ndyy {
  background-color: white;
  padding: 20px;
  max-width: 400px;
  text-align: center;
}

.return-to-main-button-ndhs {
  text-align: center;
}