.cart-list-nhsya {
  min-height: 400px;
  background: white;
  padding: 20px;
}

.no-products-in-cart-Nshat {
  text-align: center;
  margin-top: 100px;
}

.price-data-Nshdy {
  border-top: 1px solid var(--color-accent-red);
  padding-top: 20px;
}