.page-Uej2 {
  width: 100%;
  min-width: 330px;
  overflow-x: hidden;
}

.content-mobile-NSdu {
  padding-top: 70px;
  min-height: 100vh;
}

.content-desktop-nJSi {
  padding-top: 90px;
  min-height: 100vh;
}