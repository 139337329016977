.footer-NdhsYs {
  padding: 20px;
  width: calc(100% - 40px);
  color: var(--color-accent-yellow);
  background-color: #373737;
  font-size: 12px;
}

.footer-block-NAhdy {
  border-left: 3px solid var(--color-accent-yellow);
  padding-left: 20px;
}

.footer-title-NSdhs {
  font-size: 14px;
  font-weight: 600;
}

.footer-NdhsYs * a {
  color: var(--color-accent-yellow);
  text-decoration: none;
}