.header-mobile-container-1sAY {
  position: fixed;
  width: 100%;
  z-index: 3;
  background: #313131;
}

.header-mobile-Nshd {
  height: 70px;
  align-items: stretch;
  background: linear-gradient(90deg, #1f1f1f, 70%, #313131);
}

.cart-mobile-hsus {
  height: 100%;
  width: 70px;
  background-color: var(--color-accent-yellow);
  color: black;
  text-align: center;
  align-content: center;
  font-size: 24px
}

.open-bar-button-12tB {
  color: var(--color-accent-yellow);
  font-size: 24px;
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity .1s;
}

.open-bar-button-12tB:hover {
  opacity: .7;
}

.header-wrapper-menu-NShd {
  padding: 20px;
  font-size: 16px;
}