.header-container-zSJ {
  width: 100%;
  position: fixed;
  z-index: 3;
}

.header-Ns12 {
  padding: 23px 30px;
  background: linear-gradient(90deg, #1f1f1f, 70%, #313131);
  align-items: center;
}

.cart-hsus {
  height: 100%;
  background-color: var(--color-accent-yellow);
  width: 100px;
  cursor: pointer;
  transition: background-color .1s;
  color: black;
  text-align: center;
  opacity: 1 !important;
}

.cart-hsus:hover {
  background-color: white;
}

.cart-content-JSy {
  justify-content: center;
}

.cart-icon-Sjdh {
  font-size: 20px;
}