.logo-dsax {
  user-select: none;
  font-family: 'Montserrat';
  font-weight: 900;
  color: var(--color-accent-red);
  font-size: 24px;
  line-height: 40px;
}

.logo-dsax:hover {
  opacity: 1 !important;
} 

@media(max-width: 1000px) {
  .logo-dsax { 
    font-size: 20px;
  }
}

.logo-text-12HS {
  text-shadow:1px 0 0 white, 0 1px 0 white, -1px 0 0 white, 0 -1px 0 white;
}

.logo-region-NZsh {
  background-color: white;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
}

.logo-mobile-dsax {
  user-select: none;
  font-family: 'Montserrat';
  font-weight: 900;
  color: var(--color-accent-red);
  font-size: 20px;
  line-height: 35px;
}

.logo-text-mobile-12HS {
  text-shadow:1px 0 0 white, 0 1px 0 white, -1px 0 0 white, 0 -1px 0 white;
}

.logo-region-mobile-NZsh {
  background-color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 20px;
}