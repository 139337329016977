.review-h4sus {
  background: white;
  padding: 20px;
}

.user-row-NSdh {
  font-size: 20px;
  color: var(--color-accent-red);
}

.review-date-SJdu {
  font-size: 14px;
  text-align: right;
  opacity: .5;
}