.description-block-hdyT3 {
  background-color: white;
  padding: 20px;
  min-height: 200px;
}

.description-title-Udhsy {
  font-weight: 600;
  color: var(--color-accent-red);
}

.no-description-Jdhg3 {
  opacity: .5;
}