.button-NZhf {
  background-color: var(--color-accent-yellow);
  height: 60px;
  line-height: 60px;
  font-weight: 600;
  padding: 0 20px;
  opacity: 1;
  transition: opacity .1s;
  cursor: pointer;
}

.button-NZhf:hover {
  opacity: 1;
}

.link-butt0n-Jdya {
  text-decoration: none;
  color: black;
}