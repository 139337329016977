.search-bar-sjsdu {
  height: 44px;
  width: 100%;
}

.search-bar-sjsdu * input[type=text] {
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 10px;
  font-size: 16px;
  font-family: "Roboto Flex";
}

.find-button-2Hdy {
  display: flex;
  height: 100%;
  width: 50px;
  background-color: var(--color-accent-yellow);
  cursor: pointer;
  transition: opacity .1s;
  font-size: 20px;
  align-items: center;
  justify-content: center;
}