.message-header-Nshd {
  color: var(--color-accent-red);
}

.card-message-sNhrs {
  background-color: white;
  padding: 20px;
}

.card-message-sNhrs * a {
  color: var(--color-accent-red);
}