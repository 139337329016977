.price-title-block {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  font-weight: 600;
  text-align: center;
  background-color: var(--color-accent-red);
  color: white;
}

.price-value-block {
    height: 60px;
  line-height: 60px;
  background: white;
  width: 100%;
  text-align: center;
  font-weight: 600;
}