.cart-product-image-J3ks {
  height: 20px;
  width: 20px;
}

.cart-product-title-nshd3 {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.change-count-button-mshd {
  height: 20px;
  width: 20px;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: black;
  background: var(--color-accent-yellow);
  cursor: pointer;
}

.change-count-button-mshd:hover {
  opacity: .7;
}

.product-info-row-Jsyw {
  line-height: 20px;
}