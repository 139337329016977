.background-container-HDsy {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.content-Jsdsy {
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
}

.background-dasj {
  position: absolute;
  filter: blur(3px);
  width: calc(100%);
  height: calc(100%);
  mix-blend-mode: darken;
  opacity: .3;
  background-size: cover;
  background-position: center center;
}

.category-button-ksH {
  position: relative;
  background-color: var(--color-accent-yellow);
  font-weight: 600;
  cursor: pointer;
  z-index: 2;
  transition: background-color .1s;
}