:root {
  --color-background: #FFF0B4;
  --color-accent-red: #C12E42;
  --color-accent-yellow: #FDD317;
  --navbar-height: 70px;
}

body {
  margin: 0;
  width: 100%;
  background-color: var(--color-background);

  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 16px;
}

h1 {
  font-family: "Roboto Flex", sans-serif;
  font-size: 22px;
  margin: 0px;
}

h2 {
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  margin: 0px;
}

h3 {
  font-family: "Roboto Flex", sans-serif;
  margin: 0px;

}

@media(max-width: 1000px) {
  html body { 
    zoom: .9;
  }
}

.page-content-NAjSy {
  padding: 20px 30px;
}

.content-filler-nAJsy {
  margin-left: 20px;
  width: 300px;
}

@media(max-width: 1500px) {
  .content-filler-nAJsy {
    display: none;
  }
}

p {
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 5px;
}