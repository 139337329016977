.page-button-J43hs {
  background-color: var(--color-accent-yellow);
  color: black;
  text-align: center;
  padding: 10px 15px;
  font-weight: bold;
}

.page-filler-jSu {
  line-height: 35px;
  width: 20px;
  text-align: center;
}