.modal-kNsldht {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 5;
  animation: modal-in 0.2s forwards;
  outline: none;
}

.modal-content-kMdht {
  overflow: visible;
  margin-top: 12vh;
}

.tiny-LmxnHdy {
  max-width: 250px;
}

.small-ldnF5h {
  max-width: 350px;
}

.medium-8gYnG {
  max-width: 700px;
}

.large-1Jfhc {
  max-width: 80vw;
}

.fullscreen-Ksk43 {
  height: 100vh;
  width: 100vw;
  margin-top: 0px;
  border-radius: 0px;
}