.image-block-J3hs {
  min-width: 100px;
  height: 400px;
  background-color: var(--color-accent-yellow);
  width: 100%;
}

.image-miniature-j4jd {
  width: 70px;
  height: 90px;
  background-color: var(--color-accent-yellow);
}

.image-jHdy3s {
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: opacity .2s;
  object-fit: cover;
}

.empty-image-ndh3 {
  text-align: center;
  font-size: 64px;
  opacity: .4;
}

.miniatures-jy4hs {
  overflow: hidden;
}

.image-jHdy3s:hover {
  opacity: .9;
}

.modal-image-block-4h3gsS {
  height: 500px;
  overflow: hidden;
}

.image-s4hy2 {
  height: 100%;
  width: 100%;
  object-fit: cover;

}

.close-modal-button-Hsg3 {
  text-align: center;
  font-size: 16px;
  padding: 0px;
}