.create-order-jH3ys {
  background-color: white;
  padding: 20px;
}

.order-field-Nshj2 {
  border-radius: 6px;
  border: 1px solid var(--color-accent-yellow);
  outline: none;
  width: calc(100% - 22px);
  padding: 10px;
  outline: none;
  box-shadow: none;
}

.order-button-Kshdy {
  width: 100%;
  background-color: var(--color-accent-yellow);
  border-radius: 6px;
  border-width: 0px;
  padding: 15px;
  cursor: pointer;
  transition: opacity .2s;
  font-size: 18px;
}

.order-button-Kshdy:hover {
  opacity: .8;
}

.order-button-Kshdy:active {
  opacity: .6;
}

.error-Nhs3 {
  border-color: red;
}

.error-modal-content-sHda {
  text-align: center;
}

.order-button-Kshdy:disabled {
  background: gray;
  opacity: 1 !important;
  cursor: default;
  color: black;
}