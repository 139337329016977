.link-JShGS {
    color: var(--color-accent-yellow);
    transition: 0.1s opacity;
    display: inline-block;
    opacity: .5;
    text-decoration: none;
}

.enabled-NZhaY {
    cursor: pointer;
    opacity: 1;
}

.enabled-NZhaY:hover {
    opacity: 0.7;
}
