.product-card-container-34H {
  position: relative;
}

.product-card-7Hsy {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-accent-yellow);
  cursor: pointer;
  transition: background-color .1s;
}

.product-card-7Hsy:hover {
  background-color: white;
}

.price-NAj {
  text-align: right;
  font-weight: 600;
  color: var(--color-accent-red);
  font-size: 20px;
}

.description-Nahd {
  padding: 0 10px;
  height: calc(100% - 10px);
}

.title-9Y3 {
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; 
  color: black;
  text-decoration: none;
}

.no-image-Nansy {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: var(--color-background);
}

.image-container-GKsh {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.image-Jsud {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}